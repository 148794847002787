import { Action, createReducer, on } from '@ngrx/store';
import { Paginate } from '../../../models/pagination.model';
import { BaseBundle, Bundle } from '../models/base-bundle.model';
import { BundleDocument } from '../models/bundle-document.model';
import { Nullable } from './../../../types/nullable.type';
import { bundleUploadDocumentsActions, loadBundleActions, loadBundleDocumentPreviewActions } from './bundle.actions';

export const BUNDLE_FEATURE_KEY = 'bundle';

interface DocumentPreviewState {
  previewBundle: Nullable<Bundle>;
  previewDocumentDetails: Nullable<BundleDocument>;
  previewDocument: Nullable<Blob>;
  previewDocumentLoaded: boolean;
}

interface DocumentsUploadState {
  files: Nullable<File[]>;
}

const initialDocumentPreviewState: DocumentPreviewState = {
  previewBundle: null,
  previewDocumentDetails: null,
  previewDocument: null,
  previewDocumentLoaded: false,
};

const initialDocumentsUploadState: DocumentsUploadState = {
  files: null,
};

export interface BundleState extends DocumentPreviewState, DocumentsUploadState {
  baseBundles: Nullable<Paginate<BaseBundle>>;
  baseBundlesLoaded: boolean;
}

export const initialBundleState: BundleState = {
  baseBundles: null,
  baseBundlesLoaded: false,
  ...initialDocumentPreviewState,
  ...initialDocumentsUploadState,
};

const reducer = createReducer(
  initialBundleState,
  on(loadBundleActions.loadBaseBundles, (state) => ({
    ...state,
    baseBundlesLoaded: false,
  })),
  on(loadBundleActions.loadBaseBundlesSuccess, (state, { bundles }) => ({
    ...state,
    baseBundles: bundles,
    baseBundlesLoaded: true,
  })),
  on(loadBundleActions.clearBaseBundles, (state) => ({
    ...state,
    baseBundles: null,
    baseBundlesLoaded: false,
  })),

  // Document Preview
  on(loadBundleDocumentPreviewActions.loadDocumentPreview, (state, { document }) => ({
    ...state,
    previewDocumentDetails: document,
  })),
  on(loadBundleDocumentPreviewActions.loadDocumentPreviewSuccess, (state, { document }) => ({
    ...state,
    previewDocument: document,
    previewDocumentLoaded: true,
  })),
  on(loadBundleDocumentPreviewActions.clearDocumentPreview, (state) => ({
    ...state,
    previewDocumentDetails: null,
    previewDocument: null,
    previewDocumentLoaded: false,
  })),

  // Documents Upload
  on(bundleUploadDocumentsActions.showUploadFilesDialog, (state, { files }) => ({
    ...state,
    files,
  }))
);

export function bundleReducer(state: BundleState | undefined, action: Action) {
  return reducer(state, action);
}
