import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Chat } from '../models/chat.model';

export const loadChatQAActions = createActionGroup({
  source: '[Chat/API] Load Chat QA',
  events: {
    'Create QA Chat': props<{ message: string; bundleId: string; agent: string }>(),
    'Create QA Chat Success': props<{ chat: Chat }>(),
    'Clear QA Chat': emptyProps(),
  },
});
