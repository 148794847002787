import { HttpContextToken, HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AlertService } from '../ui-kit/services/alert.service';

export const SKIP_API_URL = new HttpContextToken<boolean>(() => false);
export const SKIP_GLOBAL_ERROR = new HttpContextToken<boolean>(() => false);

const DISABLED_ERROR_MESSAGES = ['USERNAME_AND_PASSWORD_OR_REFRESH_TOKEN_ARE_MANDATORY'];

export const httpInterceptor: HttpInterceptorFn = (request, next) => {
  const alertService = inject(AlertService);

  const modifiedRequest = request.clone({
    ...(!request.context.has(SKIP_API_URL) && { withCredentials: true, url: `${environment.apiUrl}${request.url}` }),
  });

  return next(modifiedRequest).pipe(
    catchError((error) => {
      if (error instanceof HttpErrorResponse && !request.context.has(SKIP_GLOBAL_ERROR)) {
        const { message } = error.error;

        !DISABLED_ERROR_MESSAGES.includes(message) &&
          alertService.showAlert(`server.errors.${message}`, 'common.attention');
      }

      return throwError(() => error);
    })
  );
};
