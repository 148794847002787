import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Paginate } from '../../../models/pagination.model';
import { BaseBundle } from '../models/base-bundle.model';
import { BundleDocumentPreviewResponse } from '../models/bundle-document-preview-response.model';
import { BundleDocument } from '../models/bundle-document.model';
import { CreateBundlePayload } from '../models/create-bundle-payload.model';

export const bundleUploadDocumentsActions = createActionGroup({
  source: '[Bundle/API] Bundle Upload Documents Actions',
  events: {
    'Show Upload Files Dialog': props<{ files: File[] }>(),
  },
});

export const loadBundleActions = createActionGroup({
  source: '[Bundle/API] Load Bundle',
  events: {
    'Load Base Bundles': props<{ page?: number; departmentId?: string; query?: string }>(),
    'Load Base Bundles Success': props<{ bundles: Paginate<BaseBundle> }>(),
    'Create Bundle': props<{ payload: CreateBundlePayload; departmentId?: string }>(),
    'Delete Bundle': props<{ id: string; departmentId?: string }>(),
    'Clear Base Bundles': emptyProps(),
  },
});

export const loadBundleDocumentPreviewActions = createActionGroup({
  source: '[Bundle/API] Load Bundle Document Preview',
  events: {
    'Load Document Preview': props<{ bundleId: string; document: BundleDocument }>(),
    'Load Document Preview From Signed URL': props<{
      previewResponse: BundleDocumentPreviewResponse;
    }>(),
    'Load Document Preview Success': props<{ document: Blob }>(),
    'Clear Document Preview': emptyProps(),
  },
});
