import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, switchMap, tap, withLatestFrom } from 'rxjs';
import { SKIP_API_URL } from '../../../../core/interceptor/http.interceptor';
import { Paginate } from '../../../models/pagination.model';
import { ResponseDto } from '../../../models/response-dto.model';
import { Nullable } from '../../../types/nullable.type';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../utils/pagination.utils';
import { BaseBundle, Bundle } from '../models/base-bundle.model';
import { BundleDocumentPreviewResponse } from '../models/bundle-document-preview-response.model';
import { BundleDocumentUploadResponse } from '../models/bundle-document-upload-response.model';
import { BundleDocument } from '../models/bundle-document.model';
import { CreateBundlePayload } from '../models/create-bundle-payload.model';

@Injectable({
  providedIn: 'root',
})
export class BundleService {
  private readonly http = inject(HttpClient);

  private _bundleDocuments$ = new BehaviorSubject<Nullable<Paginate<BundleDocument>>>(null);
  private _selectedBaseBundle$ = new BehaviorSubject<Nullable<BaseBundle>>(null);
  private _selectedBundle$ = new BehaviorSubject<Nullable<Bundle>>(null);

  bundleDocuments$ = this._bundleDocuments$.asObservable();
  selectedBaseBundle$ = this._selectedBaseBundle$.asObservable();
  selectedBundle$ = this._selectedBundle$.asObservable();

  getBaseBundles(
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    departmentId?: string,
    query?: string
  ): Observable<Paginate<BaseBundle>> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (departmentId) {
      params = params.set('departmentId', departmentId);
    }

    if (query) {
      params = params.set('bundleName', query);
    }

    return this.http
      .get<ResponseDto<Paginate<BaseBundle>>>(`/management-bundles`, { params })
      .pipe(map((bundles) => bundles.data));
  }

  getBundleById(id: string): Observable<Bundle> {
    return this.http.get<ResponseDto<Bundle>>(`/management-bundles/${id}`).pipe(
      map((response) => response.data),
      tap((bundle) => this._selectedBundle$.next(bundle))
    );
  }

  deleteBundleById(id: string): Observable<void> {
    return this.http.delete<void>(`/management-bundles/${id}`);
  }

  getBundleDocumentsById(
    id: string,
    page = DEFAULT_PAGE,
    pageSize = DEFAULT_PAGE_SIZE,
    name?: string
  ): Observable<Paginate<BundleDocument>> {
    let params = new HttpParams().set('page', page).set('pageSize', pageSize);

    if (name !== undefined) {
      params = params.set('name', name);

      this.clearBundleDocuments();
    }

    return this.http.get<ResponseDto<Paginate<BundleDocument>>>(`/management-bundles/${id}/documents`, { params }).pipe(
      map((response) => response.data),
      withLatestFrom(this._bundleDocuments$),
      map(([paginatedDocuments, currentDocuments]) => {
        const aggregatedDocuments: Paginate<BundleDocument> = {
          ...paginatedDocuments,
          items: [...(currentDocuments?.items || []), ...paginatedDocuments.items],
        };

        return aggregatedDocuments;
      }),
      tap((paginatedDocuments) => this._bundleDocuments$.next(paginatedDocuments))
    );
  }

  clearBundleDocuments(): void {
    this._bundleDocuments$.next(null);
  }

  clearSelectedBundle(): void {
    this._bundleDocuments$.next(null);
    this._selectedBundle$.next(null);
  }

  uploadDocument(
    bundleId: string,
    fileName: string,
    file: File,
    override: boolean,
    verticals: string[] = []
  ): Observable<any> {
    return this.http
      .post<ResponseDto<BundleDocumentUploadResponse>>(`/management-bundles/${bundleId}/documents`, {
        fileName,
        override,
        verticals,
      })
      .pipe(
        switchMap(({ data }) => {
          const fileMetadata = data.fileMetadata;
          const bundleId = fileMetadata['x-goog-meta-bundleId'];
          const fileId = fileMetadata['x-goog-meta-fileId'];
          const tenantId = fileMetadata['x-goog-meta-tenantId'];
          const agentIds = fileMetadata['x-goog-meta-agentIds'];
          const fileName = fileMetadata['x-goog-meta-fileName'];

          return this.uploadOnGoogleBucket(data.link, file, bundleId, fileId, tenantId, agentIds, fileName).pipe(
            switchMap(() => this.confirmDocumentUpload(bundleId, fileId))
          );
        })
      );
  }

  getDocumentPreviewUrl(bundleId: string, documentId: string): Observable<BundleDocumentPreviewResponse> {
    return this.http
      .get<ResponseDto<BundleDocumentPreviewResponse>>(`/management-bundles/${bundleId}/documents/${documentId}`)
      .pipe(map((response) => response.data));
  }

  confirmDocumentUpload(bundleId: string, documentId: string): Observable<void> {
    return this.http.patch<void>(`/management-bundles/${bundleId}/documents/${documentId}`, {});
  }

  checkIfFileExists(bundleId: string, fileName: string): Observable<boolean> {
    return this.http
      .get<ResponseDto<{ exists: boolean }>>(`/management-bundles/${bundleId}/documents/${fileName}/exists`)
      .pipe(map((response) => response.data.exists));
  }

  deleteDocument(bundleId: string, documentId: string): Observable<void> {
    return this.http.delete<void>(`/management-bundles/${bundleId}/documents/${documentId}`);
  }

  setSelectedBaseBundle(bundle: BaseBundle | null): void {
    this._selectedBaseBundle$.next(bundle);
  }

  getFilePreviewFromSignedUrl(
    url: string,
    bundleId: string,
    fileId: string,
    tenantId: string,
    agentIds: string,
    fileName: string
  ): Observable<Blob> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'x-goog-meta-bundleId': bundleId,
      'x-goog-meta-fileId': fileId,
      'x-goog-meta-tenantId': tenantId,
      'x-goog-meta-agentIds': agentIds,
      'x-goog-meta-fileName': fileName,
    });

    return this.http
      .get<void>(url, {
        headers,
        // @ts-ignore
        responseType: 'arraybuffer',
        context: new HttpContext().set(SKIP_API_URL, true),
      })
      .pipe(map((data: ArrayBuffer) => new Blob([data], { type: 'application/pdf' })));
  }

  createBundle(payload: CreateBundlePayload): Observable<ResponseDto<void>> {
    return this.http.post<ResponseDto<void>>(`/management-bundles`, payload);
  }

  private uploadOnGoogleBucket(
    url: string,
    file: File,
    bundleId: string,
    fileId: string,
    tenantId: string,
    agentIds: string,
    fileName: string
  ): Observable<void> {
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'x-goog-meta-bundleId': bundleId,
      'x-goog-meta-fileId': fileId,
      'x-goog-meta-tenantId': tenantId,
      'x-goog-meta-agentIds': agentIds,
      'x-goog-meta-fileName': fileName,
    });

    return this.http.put<void>(url, file, {
      headers,
      context: new HttpContext().set(SKIP_API_URL, true),
    });
  }
}
