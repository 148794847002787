import { Action, createReducer, on } from '@ngrx/store';
import { Nullable } from '../../../types/nullable.type';
import { Chat } from '../models/chat.model';
import { loadChatQAActions } from './chat.actions';

export const CHAT_FEATURE_KEY = 'chat';

export interface ChatState {
  chatQA: Nullable<Chat>;
  chatQALoaded: boolean;
}

export const initialChatState: ChatState = {
  chatQA: null,
  chatQALoaded: false,
};

const reducer = createReducer(
  initialChatState,
  on(loadChatQAActions.createQAChat, (state) => ({
    ...state,
    chatQALoaded: false,
  })),
  on(loadChatQAActions.createQAChatSuccess, (state, { chat }) => ({
    ...state,
    chatQA: chat,
    chatQALoaded: true,
  })),
  on(loadChatQAActions.clearQAChat, (state) => ({
    ...state,
    chatQA: null,
    chatQALoaded: false,
  }))
);

export function chatReducer(state: ChatState | undefined, action: Action) {
  return reducer(state, action);
}
