import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { Nullable } from '../../../../shared/types/nullable.type';
import { BundleSummary } from '../models/bundle-summary.model';

export const loadSummaryActions = createActionGroup({
  source: '[Summary/API] Load Summary',
  events: {
    'Load Summary By ID': props<{ id: string }>(),
    'Load Summary By ID Success': props<{
      summary: Nullable<BundleSummary>;
    }>(),
    'Upload Summary CSV': props<{ id: string; file: File }>(),
    'Generate Summary By ID': props<{ id: string }>(),
    'Validate Summary Fields': props<{ id: string; fields: string[] }>(),
    'Delete Summary By ID': props<{ id: string }>(),
    'Clear Summary': emptyProps(),
  },
});
