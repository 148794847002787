import { Action, createReducer, on } from '@ngrx/store';
import { Nullable } from '../../../../shared/types/nullable.type';
import { BundleSummary } from '../models/bundle-summary.model';
import { loadSummaryActions } from './summary.actions';

export const SUMMARY_FEATURE_KEY = 'summary';

export interface SummaryState {
  summary: Nullable<BundleSummary>;
  summaryLoaded: boolean;
}

export const initialSummaryState: SummaryState = {
  summary: null,
  summaryLoaded: false,
};

const reducer = createReducer(
  initialSummaryState,
  on(loadSummaryActions.loadSummaryByID, (state) => ({
    ...state,
    summaryLoaded: false,
  })),
  on(loadSummaryActions.loadSummaryByIDSuccess, (state, { summary }) => ({
    ...state,
    summary,
    summaryLoaded: true,
  }))
);

export function summaryReducer(state: SummaryState | undefined, action: Action) {
  return reducer(state, action);
}
